<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters">
                <div class="col l-3 m-3 c-12">
                    <comment :objectType="54" :objectId="this.$route.params.id" />
                </div>
                <div class="col l-9 m-9 c-12">
                    <div class="page-list-body">
                        <div class="row no-gutters page-list-header">
                            <div class="col l-6 m-6 c-12">
                                <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Tạm ứng chi phí dự án stst</span></h4>
                            </div>
                            <div class="col l-6 m-6 c-12">
                            </div>
                        </div>
                        <div class="form-body">
                            <div class="wrapper">
                                <div class="row no-gutters">
                                    <div class="col l-4 m-4 c-12">
                                        <div class="left">
                                            <img src="../../../assets/images/logo.png" />
                                        </div>
                                    </div>
                                    <div class="col l-8 m-8 c-12">
                                        <div class="center">
                                            <div class="title"><h2>Tạm ứng chi phí dự án stst</h2></div>
                                            <div class="code"><span class="label">Số: </span><span>{{entity.advancePaymentCode}}</span></div>
                                            <div class="date"><span class="label">Ngày: </span><span>{{entity.advancePaymentDate}}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters" style="padding-top:10px;">
                                    <div class="col l-12 m-12 c-12">
                                        <div class="line"> 
                                            <label>Người lập phiếu: </label>
                                            <div>{{entity.staff.fullName}}</div>
                                        </div>
                                        <div class="line-2">
                                            <div class="line"> 
                                                <label>Ghi chú: </label>
                                                <div>{{entity.note}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="display: flex; margin: 0 auto;">
                                    <div style="display: flex; margin: 10px auto;">
                                        <table style="border-collapse: collapse;border-spacing: 0; min-height: auto !important;">
                                            <thead>
                                                <tr>
                                                    <th style="width:50px; padding: 5px 10px; border: 1px solid #e0e0e0;">Stt</th>
                                                    <th style="width:500px; padding: 5px 10px; border: 1px solid #e0e0e0;">Tên phí</th>
                                                    <th style="width:200px; padding: 5px 10px; border: 1px solid #e0e0e0;">Loại phí</th>
                                                    <th style="width:150px; padding: 5px 10px; border: 1px solid #e0e0e0;">Dự toán</th>
                                                    <th style="width:150px; padding: 5px 10px; border: 1px solid #e0e0e0;">Tạm ứng</th>
                                                </tr>
                                            </thead>
                                            <tbody v-for="(item, index) in details" :key="'item-' + index">
                                                <tr>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 0px; text-align: center;">{{romanize(index + 1)}}</td>
                                                    <td colspan="4" style="font-weight: bold; border: 1px solid #e0e0e0; padding: 5px; text-align: left;">{{ item.key }}</td>
                                                </tr>
                                                <tr v-for="(obj, i) in item.list" :key="'detail' + i">
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 0px; text-align: center;">{{i + 1}}</td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 5px 5px 25px; text-align: left;">{{ obj.feeName }}</td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px 10px 5px 10px;">{{ getFeeType(obj.type) }}</td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px; text-align: right;">
                                                        {{ formatNumber(obj.estimateAmount) }}
                                                    </td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px; text-align: right;">
                                                        {{ formatNumber(obj.advancePaymentAmount) }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="3" style="font-weight: bold; border: 1px solid #e0e0e0; padding: 5px 5px; text-align: right;">Tổng cộng</td>
                                                    <td style="font-weight: bold; border: 1px solid #e0e0e0; padding: 5px; text-align: right;">{{ formatNumber(entity.estimateList.filter(x => x.estimate.estimateCode == item.key)[0].estimateAmount) }}</td>
                                                    <td style="font-weight: bold; border: 1px solid #e0e0e0; padding: 5px; text-align: right;">{{ formatNumber(entity.estimateList.filter(x => x.estimate.estimateCode == item.key)[0].amount) }}</td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr>
                                                    <td colspan="4" style="text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;"><span style="font-weight: bold;font-size: 16px;">Tổng tạm ứng</span></td>
                                                    <td style="border: 1px solid #e0e0e0; padding: 5px;text-align: right; "><span style="font-weight: bold; font-size: 16px;">{{ formatNumber(entity.amount) }}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
    import projectAdvancePaymentService from '../../../api/projectAdvancePaymentService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import { required } from 'vuelidate/lib/validators';
    import comment from '../../../components/Comment.vue';

    export default ({
        components: {
            comment
        },
        metaInfo: {
            title: 'Tạm ứng chi phí dự án'
        },
        data() {
            return {
                title: 'Tạm ứng chi phí dự án',
                id: 0,
                submitted: false,
                loadding: false,
                details: [],
                entity: {}
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
                this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            getById(){
                this.setLoading(true);
                projectAdvancePaymentService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;

                        const details = this.groupBy(this.entity.details);
                        this.details = [];

                        Object.keys(details).forEach((item, index) => {
                            const objItem = { key: item, list: details[item] };
                            details[index] = details[item];
                            delete details[item];
                            this.details.push(objItem);
                        });
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            groupBy(list) {
                const groups = list.reduce((groups, item) => ({
                    ...groups,
                    [item.estimateCode]: [...(groups[item.estimateCode] || []), item]
                }), {});
                return groups;
            },

            romanize(num) {
                if (isNaN(num))
                    return NaN;
                var digits = String(+num).split(""),
                    key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                        "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                        "","I","II","III","IV","V","VI","VII","VIII","IX"],
                    roman = "",
                    i = 3;
                while (i--)
                    roman = (key[+digits.pop() + (i * 10)] || "") + roman;
                return Array(+digits.join("") + 1).join("M") + roman;
            },

            back(){
                this.$router.push('/project-advance-payment/approve-list');
            }
        },
        validations: {
            note: { required },
        }
    })

</script>

<style scoped>
    table.table-1 input {
        height: 30px;
        width: 100%;
        border: 1px solid #e0e0e0;
        padding: 0 5px;
    }
    .md-checkbox {
        margin: 0 !important;
    }
    .md-content {
        padding: 0 15px 0px 15px;
        width: 550px;
        height: 455px;
        max-width: 550px;
        max-height: 150px;
    }
    .dialog-content {
        height: 150px;
    }
    .action {
        position: sticky;
        bottom: 20px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
    }
    .wrapper {
        margin: 0 auto;
        width: 1000px;
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .header {
        display: flex;
        justify-content: space-around;
        border: 1px solid #999;
        position: relative;
    }
    .left, .center, .right {
        height: 130px;
    }
    .left {
        border-top: 1px solid #999;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .left img{
        height: 100px;
        width: 220px;
    }
    .center {
        border-top: 1px solid #999;
        border-bottom: 1px solid #999;
        border-right: 1px solid #999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .center .title {
        display: flex;
        
    }
    .center .title h2 {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
    }
    .center .code, .center .date {
        font-size: 16px;
    }
    .label {
        font-weight: bold;
    }
    .approve {
        display: flex;
        justify-content: space-around;
        padding-top: 20px;
    }
    .approve-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .approve-item .title {
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .approve-item .approve-status {
        padding: 20px 0;
    }
    .approve-item .approve-status .md-chip {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve-item .approve-status i{
        color: #00d25b;
        font-size: 25px;
        padding-right: 5px;
    }
    .approve-item .name {
        font-size: 15px;
        font-weight: bold;
    }
    .line {
        display: flex;
        padding-top: 5px;
        align-items: center;
    }
    .line label {
        font-weight: bold;
        padding-right: 10px;
        font-size: 15px;
    }
    .line span{
        font-size: 15px;
        font-weight: bold;
    }
    .line div {
        font-size: 15px;
    }
    .line-item {
        padding-right: 5px;
    }
    .line-item::after {
        content: ',';
    }
    .line-item:last-child::after {
        content: '';
    }
    .line-2 {
        display: flex;
    }
    .line-2 .line:first-child {
        padding-right: 40px;
    }
    .line-3 {
        display: flex;
        justify-content: space-between;
    }
    .table-1 {
        width: 100%;
    }
    .stt {
        text-align: center;
    }
    .table-1 td, .table-1 th {
        font-size: 14px;
    }
    .table-1 {
        min-height: 140px !important;
    }
    .currency {
        text-align: right;
    }
    b {
        font-weight: bold;
        font-size: 17px;
    }
</style>
